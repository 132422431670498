import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import translate from "../utils/translation"
import styles from "./doc.module.scss"


class DocTemplate extends React.Component {

  render() {
    const post = this.props.data.markdownRemark
    const lang = post.fields.slug.split('/')[1]
    const { parent, altPost } = this.props.pageContext
    const siteTitle = translate('site.name', lang)
    const headerSubTitle = parent ? parent.frontmatter.title : translate('site.description', lang)

    return (
      <Layout location={this.props.location} title={siteTitle} subTitle={headerSubTitle} slug={post.fields.slug} hasLanguage={!!altPost}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          lang={lang}
          slug={post.fields.slug}
        />
        <article className="main-content">
          <div className={styles.container}>
            <h1 className={styles.title}>{post.frontmatter.title}</h1>
            <p className={styles.description}>{post.frontmatter.description}</p>
            <div dangerouslySetInnerHTML={{ __html: post.html }} className={styles.text}></div>
            {
              parent && (
                <div className={styles.control}>
                  <Link rel="back"
                    to={parent.fields.slug}
                    style={{
                      boxShadow: `none`,
                      textDecoration: `none`,
                      color: `inherit`,
                    }}>
                    <button>Back</button>
                  </Link>
                </div>
              )
            }
          </div>
        </article>
      </Layout>
    )
  }
}

export default DocTemplate

export const pageQuery = graphql`
  query DocBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
